import axiosInstall from './axiosInstance';

export function create(comment) {
    return axiosInstall.post('front/article/create',comment)
}


export function del(id) {
    return axiosInstall.post('comment/delete?id='+id)
}


export function query(comment) {
    return axiosInstall.post('comment/query',comment)
}

export function detail(id) {
    return axiosInstall.post('comment/detail?id='+id)
}
export function update(comments) {
    return axiosInstall.post('comment/update',comments)
}