<template>
  <div>
    <el-dialog title="修改个人信息" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
      <el-form :model="form" :rules="rules" ref="form" label-width="150px">
        <div class="updateinfo">
          <div class="left">
            <el-form-item label="头像" prop="avatar">
              <img style="width:206px;height:175px" :src="form.avatar" @click="imagecropperShow = true">
              <image-cropper v-show="imagecropperShow" :width="300" :height="300" :key="imagecropperKey"
                :url="'user/upload'" field="file" @close="closeImage" @crop-upload-success="cropSuccess" />
            </el-form-item>
            <el-form-item label="账号密码" prop="password">
              <el-input v-model="form.password" show-password></el-input>
            </el-form-item>
            <el-form-item label="昵称" prop="nickname">
              <el-input v-model="form.nickName"></el-input>
            </el-form-item>
            <el-form-item label="年龄" prop="age">
              <el-input v-model="form.age"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              
              <el-radio-group v-model="form.sex">
                <el-radio-button label="1">男</el-radio-button>
                <el-radio-button label="2">女</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
          </div>
          <div class="right">
            <el-form-item label="用户编号" prop="id">
              <el-input v-model="form.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="地区" prop="area">
              <el-input v-model="form.area"></el-input>
            </el-form-item>
            <el-form-item label="兴趣爱好" prop="hobby">
              <el-input v-model="form.hobby"></el-input>
            </el-form-item>
            <el-form-item label="职业" prop="work">
              <el-input v-model="form.work"></el-input>
            </el-form-item>
            <el-form-item label="个性签名" prop="design">
              <el-input v-model="form.design"></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="mobilePhoneNumber">
              <el-input v-model="form.mobilePhoneNumber"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ImageCropper from '../../../components/ImageCropper'
import { update,detail } from "@/api/user.js";
import token from "@/store/token.js"
export default {
  name: "PersonalDia",
  components: { ImageCropper },
  data() {
    return {
      dialogVisible: false,
      imagecropperShow: false,
      imagecropperKey: 0,
      form: {
        avatar: "",
        password: "",
        nickName: "",
        age: Number,
        email: "",
        mobilePhoneNumber: "",
        sex: 0,
        id: Number,
        account: "",
        area: "",
        hobby: "",
        work: "",
        design: "",
      },
      rules: {
        nickName: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
        ],
        // password: [
        //   { required: true, message: "账号密码不能为空", trigger: "blur" },
        // ],
      },
    };
  },

  created() {
    // this.form = token.getUser()
    const user = JSON.parse(localStorage.getItem('user'))
    detail(user.id).then(data => {
      this.form = data.data
      // this.sex =  this.defForm .sex == 1 ? "男" : "女"
    })
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    submit() {
      update(this.form)
        .then((data) => {
          this.$message.success(data.message)
          this.dialogVisible = false;
          this.$router.go(0)
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 上传成功后的回调函数
    cropSuccess(data) {
      //使用该属性，保存上传之前的头像
      this.imagecropperShow = false
      this.form.avatar = this.image = data;
      // 上传成功后，重新打开上传组件时初始化组件，否则显示上一次的上传结果
      this.imagecropperKey = this.imagecropperKey + 1
    },
    // 关闭上传组件
    closeImage() {
      this.imagecropperShow = false
      // 上传失败后，重新打开上传组件时初始化组件，否则显示上一次的上传结果
      this.imagecropperKey = this.imagecropperKey + 1
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("flesh");
    },
  },
};
</script>

<style scoped>
.updateinfo {
  height: 350px;
  overflow: auto;
}

.left {
  /* width: 330px; */
  float: left;
}

.right {
  overflow: hidden;
}
</style>
