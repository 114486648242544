import axiosInstall from './axiosInstance';

// 轮播图
export function query() {
  return axiosInstall.post('swiper/query')
}
export function deleteSwipe(id){
  return axiosInstall.post('swiper/delete?id=' + id)
}
export function create(swiper){
  return axiosInstall.post('swiper/create',swiper)
}
