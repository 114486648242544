<template>
	<div v-title data-title="Mr_Yu">
		<Header @child-even="parentEven" @child-evenTitle="parentEvenTitle"></Header>
		<article>
			<transition 
			appear
			name="animate__animated animate__bounce" 
			enter-active-class="animate__fadeInTopRight"
			>
			<div class="lbox">
					<Swiper></Swiper>
					<Table v-if="xx"></Table>
					<NewBlog v-if="xx" 
					:search="newData" 
					:childEvenTitle="title"
					:childEven="articles"
					></NewBlog>
			</div>
		</transition>

		<transition 
			appear
			name="animate__animated animate__bounce" 
			enter-active-class="animate__fadeInTopLeft"
			>
			<div class="rbox">
				<AboutMe></AboutMe>
				<Search @childEvenTitle="childEvenTitle" @childEven="childEven"></Search>
				<Notice></Notice>
				<Order></Order>
				<Tag></Tag>
				<FriendLink></FriendLink>
			</div>
		</transition>
		</article>
		<Footer></Footer>
	</div>
		
</template>

<script>
	import 'animate.css'
	import '@/assets/css/base.css'
	import '@/assets/css/m.css'
	import Header from "../../components/front/Header";
	import Swiper from "../../components/front/Swiper";
	import AboutMe from "../../components/front/AboutMe";
	import Table from "../../components/front/Table";
	import NewBlog from "../../components/front/NewBlog";
	import Notice from "../../components/front/Notice";
	import Order from "../../components/front/Order";
	import FriendLink from "../../components/front/FriendLink";
	import Footer from "../../components/front/Footer";
	import Tag from '../../components/front/Tag';
	import Search from '../../components/front/search.vue';



	export default {
		name: "Index",
		components: {
			Footer,
			FriendLink,
			Order,
			Notice,
			NewBlog,
			Table,
			AboutMe,
			Swiper,
			Header,
			Tag,
			Search
		},
		data() {
			return {
				articles: [],
				pages: '',
				total: 0,
				pageNo: '',
				xx: true,
				newData: '',
				title: ''
			}
		},
		beforeRouteUpdate(to, from, next) {
			this.xx = false;
			this.$nextTick(() => {
				this.xx = true
			})
			next()
		},
		methods: {
			parentEven(data) {
				this.newData = data;
			},
			parentEvenTitle(data) {
				this.title = data
			},
			childEvenTitle(childEvenTitle){
				this.title = childEvenTitle
			},
			childEven(childEven){
				this.articles = childEven
			}
		},
		mounted() {

		}
	}
</script>

<style scoped>

</style>
