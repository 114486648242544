<template>
    <div class="login-page" v-title data-title="博客后台 | 登录">
        <transition appear name="animate__animated animate__bounce" enter-active-class="animate__lightSpeedInLeft">
            <div class="login-page-bg">
                <h2 style="opacity: 1;font-weight: 300">博客后台登录</h2>
                <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                    <el-form-item prop="userName">
                        <el-input placeholder="请输入用户名" prefix-icon="el-icon-user-solid"
                            v-model="form.userName"></el-input>
                    </el-form-item>

                    <el-form-item prop="password">
                        <el-input placeholder="请输入登录密码" prefix-icon="el-icon-lock" v-model="form.password"
                            show-password></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button style="width: 48%" type="primary" :loading="loading"
                            @click="goback()">返回主页</el-button>
                        <el-button style="width: 48%" type="primary" :loading="loading" native-type="submit"
                            @click="submit()">立即登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            form: {
                userName: '',
                password: ''
            },
            loading: false,
            rules: {
                userName: [{ required: true, message: '请输入用户名', trigger: "blur" }],
                password: [{ required: true, message: '请输入登录密码' }],
            }
        }
    },
    created() {
        //页面加载完全局监听键盘事件，然后调用函数
        document.addEventListener("keyup", this.watchEnter)
    },
    destroyed() {
        //页面跳转销毁全局监听事件
        document.removeEventListener("keyup", this.watchEnter)
    },
    // TypeError: Cannot read properties of undefined (reading 'list')
    methods: {
        submit() {
            this.$refs['form'].validate(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('login', this.form).then((data) => {
                        this.$message.success('登录成功')
                        this.$router.push('/user')
                    }).catch(error => { 
                        this.loading = false
                        this.$message.error(error)
                    })

                }
            })
        },
        goback() {
            this.$router.push("/")
        }
    }
}
</script>

<style scoped>
.login-page {
    height: 100%;
    background-size: cover;
}

.login-page-bg {
    position: absolute;
    top: 30%;
    left: 50%;
    right: 30%;
    bottom: 25%;
    width: 500px;
    height: 300px;
    border-radius: 5px;
    padding-top: 20px;
    color: white;
    text-align: center;
    padding-right: 50px;
    box-shadow: #666 0px 0px 10px;
    -webkit-box-shadow: #666 0px 0px 10px;
    -moz-box-shadow: #666 0px 0px 10px;
    margin-left: -250px;

}

.login-page-bg h2 {
    margin-bottom: 30px;
    margin-left: 7%;
}
</style>