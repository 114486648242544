<template>
  <div v-title data-title="博客后台 | 添加轮播图">
      <el-row :gutter="15">
          <el-form ref="form" :model="form" :rules="rules" size="medium" label-width="100px">
              <el-col :span="15">
                  <el-form-item label="图片地址" prop="imgUrl">
                      <el-input v-model="form.imgUrl"  placeholder="输入图片地址" clearable :style="{width: '100%'}"></el-input>
                  </el-form-item>
              </el-col>

              <el-col :span="15">
                  <el-form-item label="链接" prop="url">
                      <el-input v-model="form.url" placeholder="请输入链接" clearable :style="{width: '100%'}">
                      </el-input>
                  </el-form-item>
              </el-col>
              <el-col :span="15">
                  <el-form-item>
                      <el-button @click="$router.back()">取消</el-button>
                      <el-button type="primary" :loading="loading" @click="submitForm">保存</el-button>
                  </el-form-item>
              </el-col>
          </el-form>
      </el-row>
  </div>
</template>

<script>
  import {create} from "@/api/swiper";
  export default {
      name: "Add",
      data(){
          return{
              form: {
                imgUrl: '',
                  url: ''
              },
              loading: false,
              rules: {
                imgUrl: [{required: true, message: '请输入图片地址', trigger: 'blur'}]
              }
          }
      },
      methods:{
          submitForm(){
              this.$refs['form'].validate(valid=>{
                  if (valid) {
                      this.loading=true
                      create(this.form).then(data=>{
                          this.loading=false
                          this.$message.success(data.message)
                          this.$refs['form'].resetFields()
                          this.$router.push('/swiper')
                      }).catch(error=>{
                          this.loading=true
                          this.$message.error(error)
                      })
                  }
              })
          },
         
          close(){
              this.$emit("hideDialog")
          },
      },
  }
</script>

<style scoped>

</style>