<template>
	<div class="banbox">
		<el-carousel height="260px" width="100%" border-radius="20px">
			<el-carousel-item v-for="(item,index) in sliders" :key="index">
				<a :href="item.url" target="_blank">
					<img :src="item.imgUrl" style="width:100%;height:100%;border-radius: 20px;" alt="">
				</a>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
import { query } from '../../api/swiper'
import {
	Swiper,
	SwiperSlide
} from 'vue-awesome-swiper'

import 'swiper/swiper-bundle.css'
export default {
	name: "FSwiper",
	components: {
		Swiper,
		SwiperSlide
	},
	data() {
		return {
			sliders: [],
			address:[],
			slidersIndex: 0,
			
		}
	},
	created() {
		query().then(data => {
						this.sliders = data.list
					}).catch(error => {
						this.$message.error(error)
					})
	},				
}
</script>

<style scoped>
.el-carousel__item:nth-child(2n) {
	background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
	background-color: #d3dce6;
}
</style>
