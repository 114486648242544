<template>
    <div v-title data-title="文章">
        <Header></Header>
        <article>
            <transition appear name="animate__animated animate__bounce" enter-active-class="animate__fadeInTopRight">
                <div class="lbox">
                    <div class="content_box whitebg" style="position: relative;">
                        <h2 class="htitle"><span class="con_nav">您现在的位置是：<a href="/">首页</a>&gt;<a href="/">{{
                            articles.title
                                    }}</a></span>正文</h2>
                        <h1 class="con_tilte">{{ articles.title }}</h1>
                        <p class="bloginfo" v-if="articles.user != undefined "><i class="avatar" ><img :src="articles.user.avatar"></i><span>{{
                            articles.user.nickName
                                }}</span><span>{{ articles.createDate }}</span><span style="color: red;">{{
                                    articles.articleView
                                }}</span>人已观看
                            <span style="color: red;">{{ count }}</span>人已收藏
                            <span style="font-size: 18px ">
                                <i :class="isThrm == 1 ? 'el-icon-star-on' : 'el-icon-star-off'"
                                    :style="{ color: isThrm == 1 ? '#fea000' : '' }" @click="addCollection(isThrm)"></i>
                            </span>

                        </p>
                        <p class="con_info"><b>摘要</b>{{ articles.summary }}</p>
                        <div class="con_text">
                            <v-md-preview :text="articles.content"></v-md-preview>
                        </div>

                    </div>

                    <div id="comments" class="comments-area"
                        v-if="articles.commentStatus == 0 || articles.commentStatus == null">
                        <ol class="comment-list">
                            <li class="comments-anchor">
                                <div id="respond" class="comment-respond">
                                    <h3 id="reply-title" class="comment-reply-title"><span
                                            id="reply-title-word">浅浅冒个泡：</span>
                                    </h3>
                                    <Form></Form>
                                </div>
                            </li>
                            <!-- P评论列表 -->
                            <li class="comment fadeInUp" id="comment-2044" v-for=" (item, index) in comments"
                                :key="index">
                                <div id="div-comment-2044" class="comment-body" v-if="item != null">
                                    <div class="comment-author vcard">
                                        <i class="avatar"><img :src="item.comment.avatar" alt="avatar"></i>
                                        <div style="margin-left: 60px">
                                            <a><strong @click="info(item.comment.email)" type="success">{{
                                                item.comment.author
                                            }}</strong></a>
                                            <span style="font-size: 12px; margin-left:10px">
                                                {{ item.comment.createDate }}
                                            </span>
                                            <!-- <img style="float: right;height: 20px;" src='@/assets/images/v.png'> -->
                                            <div class="dianzan">
                                                <i style="position: relative;right: 5px; font-size: 20px; top: 2px;"
                                                    class="el-icon-pear"
                                                    @click="dianzan(item.comment.id, item.comment.clickNum)"></i>
                                                <h6 style="display: inline-block;">{{ item.comment.clickNum }}人已点赞</h6>
                                                <div style="margin: 10px;">
                                                    <el-button @click="goReplay(index)">
                                                        回复
                                                    </el-button>
                                                </div>

                                            </div>
                                            <p style="margin-top: 10px;">{{ item.comment.content }}</p>
                                        </div>
                                    </div>

                                </div>

                                <div id="anchor-2044" >
                                    <el-divider></el-divider>
                                </div>
                                <!-- 回复内容 -->
                                <div v-if="item.reply.length != 0">
                                <div class="comment-author vcard" v-for="reply in item.reply">
                                    <i class="avatar" v-if="reply.replyUser != null" ><img :src="reply.replyUser.avatar" alt="avatar"></i>
                                    <div style="margin-left: 60px">
                                        <a><strong @click="info(reply.replyUser.email)" type="success">{{
                                            reply.replyUser.nickName
                                        }}</strong></a>
                                        <span style="font-size: 12px; margin-left:10px">
                                            {{ reply.replyContent.createDate }}
                                        </span>
                                        <div class="dianzan">
                                            <i style="position: relative;right: 5px; font-size: 20px; top: 2px;"
                                                class="el-icon-pear"
                                                @click="dianzanReply(reply.replyContent.id, reply.replyContent.commentId, reply.replyContent.clickNum)"></i>
                                            <h6 style="display: inline-block;">{{ reply.replyContent.clickNum }}人已点赞
                                            </h6>
                                        </div>
                                        <p style="margin-top: 10px;">@{{ item.comment.author }}：{{
                                            reply.replyContent.content }}</p>
                                    </div>
                                    <el-divider></el-divider>
                                </div>
                            </div>
                                <!-- 回复框及按钮 -->
                                <ul class="children" v-if="reployBox === index">
                                    <div>
                                        <el-form ref="replys" :model="replys" :rules="rules" v-slot="">
                                            <el-form-item prop="content">
                                                <el-input style="width: 90%; position: relative; top: 5%;"
                                                    v-model="replys.content" placeholder="平等交流，和谐发言"></el-input>
                                                <el-button
                                                    style=" float: right;position: relative;bottom: 16px;border-radius: 17px; "
                                                    type="primary"
                                                    @click="replyContent(item.comment.id, item.comment.author)">提交</el-button>
                                            </el-form-item>
                                        </el-form>
                                    </div>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </transition>
            <transition appear name="animate__animated animate__bounce" enter-active-class="animate__fadeInTopLeft">
                <div class="rbox">
                    <Order></Order>
                    <Like></Like>
                    <Tag></Tag>
                    <FriendLink></FriendLink>
                </div>
            </transition>
        </article>
        <Footer></Footer>
        <el-dialog :visible.sync="addVisble" v-if="addVisble" :close-on-click-modal="false">
            <Info @after="addseach" :userInfo="userInfo" @hideDialog="hidden"></Info>
        </el-dialog>
    </div>
</template>

<script>
import 'animate.css'
import '@/assets/css/base.css'
import '@/assets/css/m.css'
import Header from "../../components/front/Header";
import Order from "../../components/front/Order";
import FriendLink from "../../components/front/FriendLink";
import Footer from "../../components/front/Footer";
import Like from '../../components/front/Like'
import Tag from "../../components/front/Tag";
import Info from "./userInfo.vue"
import store from '@/store/index.js'
import { getArticle, getCommentArticle } from "../../api/front";
import Form from "../../components/front/Form";
import { collectI, collectU, count } from "../../api/collect"
import { update } from "../../api/comment"
import { query } from "../../api/user";
import { create, updateReply } from "@/api/reply"
export default {
    name: "Detail",
    components: {
        Form,
        Tag, Footer, FriendLink, Order, Header, Like, Info
    },
    data() {
        return {
            replys: {
                content: '',
                clickNum: 0,
                commentId: '',
                replyId: ''
            },
            replysAll: [],
            articles: {},
            comments: [],
            reployBox: null,
            collect: {},
            isReplay: false,
            xx: true,
            count: 0,
            isThrm: '',
            yUserId: '',
            addVisble: false,
            comment: {
                clickNum: 0,
                articleId: '',
                id: ''
            },
            reply: {
                clickNum: 0,
                commentId: '',
                id: ''
            },
            queryForm: {
                email: ''
            },
            really: true,
            userInfo: {},
            rules: {
                content: [{
                    required: true,
                    message: '请输入回复内容或回复内容大于三个字',
                    min: 3,
                    trigger: 'blur'
                }]
            },
        }
    },
    mounted() {
        const user = store.state.user
        if (user == undefined) {
            this.$message.error('登陆后即可查看全部内容哦!!');
            this.$router.replace('/userlogin')
        }
    },
    created() {
        const user = store.state.user
        this.replys.replyId = user.id
        this.comment.articleId = this.$route.params.id
        getArticle(this.$route.params.id, user.id).then(data => {
            if (data) {
                this.articles = data.data
                if (data.data.collect) {
                    this.isThrm = data.data.collect.isThrm
                    this.yUserId = data.data.collect.userId
                }
            }
        }).catch(error => {
            console.error(error.message)
        })
        getCommentArticle(this.$route.params.id).then(data => {
            this.comments = data.data
        })
        // queryReply(this.replys.commentId).then(data => {
        //     this.replysAll = data.data
        // })
        this.collectCount()
    },
    beforeRouteUpdate(to, from, next) {
        getArticle(to.params.id).then(data => {
            if (data) {
                this.articles = data.data
                this.user = data.data.user
            }
        })
        this.xx = false;
        this.$nextTick(() => {
            this.xx = true
        })
        next()
    },

    methods: {
        goReplay(index) {
            this.reployBox = index;
            this.isReplay = true;
        },
        replyContent(commentId, author) {
            this.replys.commentId = commentId
            this.$refs['replys'][0].validate((valid) => {
                if (valid) {
                    // console.log(commentId, author)
                    create(this.replys).then(data => {
                        this.getCommentClickNum();
                        this.$message.success(data.message)
                        this.replys.content = ""
                        // 隐藏回复框
                        this.replyBox = null;
                    }).catch(error => {
                        this.$message.error(error)
                    })
                } else {
                    return false;
                }
            }
            )
        },
        collectCount() {
            count(this.$route.params.id).then(count => {
                this.count = count.data
            })
        },

        info(email) {
            this.addVisble = true
            this.queryForm.email = email
            let param = this.queryForm
            query(param).then(data => {
                this.userInfo = data.list
            }).catch(error => {
                this.$message.error(error)
            })
        },
        addseach() {
            let param = this.queryForm
            query(param).then(data => {
                this.pages = data.pages
                this.handleCurrentChange(this.pages)
            }).catch(error => {
                this.$message.error(error)
            })
        },
        getCommentClickNum() {
            getCommentArticle(this.$route.params.id).then(data => {
                this.comments = data.data
            })
        },
        dianzan(id, clickNum) {
            this.comment.id = id
            this.comment.clickNum = clickNum + 1
            update(this.comment).then(data => {
                this.articles.clickNum = data.data.clickNum
                this.$message({
                    message: '已点赞',
                    type: 'success',
                    duration: 500,
                    onClose: () => {
                        this.getCommentClickNum();
                    }
                });
            })
        },
        dianzanReply(id, commentId, clickNum) {
            this.reply.id = id
            this.reply.commentId = commentId
            this.reply.clickNum = clickNum + 1
            updateReply(this.reply).then(data => {
                this.articles.clickNum = data.data.clickNum
                this.$message({
                    message: '已点赞',
                    type: 'success',
                    duration: 500,
                    onClose: () => {
                        this.getCommentClickNum();
                    }
                });
            })
        },
        hidden() {
            this.addVisble = false
            this.editVisble = false
        },
        addCollection(isThrm) {
            const userId = store.state.user.id
            const acticleId = this.articles.id
            if (isThrm == 1) {
                this.isThrm = 2
                collectU(acticleId, userId, this.isThrm).then(data => {
                    this.$message({
                        message: '取消收藏',
                        type: 'warning',
                        center: true,
                        duration: 500,
                        onClose: () => {
                            this.collectCount()
                        }
                    });

                })
            } else {
                if (this.isThrm != null && userId == this.yUserId) {
                    this.isThrm = 1
                    collectU(acticleId, userId, this.isThrm).then(data => {
                        this.$message({
                            message: '收藏成功',
                            type: 'success',
                            center: true,
                            duration: 500,
                            onClose: () => {
                                this.collectCount()
                            }
                        });

                    })
                } else {
                    this.isThrm = 1
                    collectI(acticleId, userId, this.isThrm).then(data => {
                        this.$message({
                            message: '收藏成功',
                            type: 'success',
                            center: true,
                            duration: 500,
                            onClose: () => {
                                this.collectCount()
                            }
                        });

                    })
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.comment-list li {
    position: relative;
    background: #fff;
    margin: 0 0 10px 0;
    padding: 15px 20px 30px 20px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    word-wrap: break-word;
    word-break: break-all;
}

.dianzan {
    float: right;
    font-size: 20px;
    position: relative;
    bottom: 10px;

}

.reply a {
    background: #fff;
    line-height: 25px;
    display: block;
    padding: 0 10px;
    border: 1px solid #ddd;
    border-radius: 2px;
}

.avatarss {
    position: relative;
    float: left;
    width: 60px;
    height: 60px;
    padding: 5px;
    display: block;
    background: -webkit-linear-gradient(bottom left, rgba(0, 153, 255, .9),
            rgba(42, 228, 197, .7));
    background: -o-linear-gradient(bottom left, rgba(0, 153, 255, .9), rgba(42, 228, 197, .7));
    background: linear-gradient(to top right, rgba(0, 153, 255, .9), rgba(42, 228, 197, .7));
}

#respond {
    background: #fff;
    margin: 0 0 10px 0;
    padding: 10px 20px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
    border-radius: 2px;
}

/deep/ .el-button {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    inset-block: 0;
}
</style>