<template>
	<div id="app">
		<!-- <vue-particles color="#f4f4f4" :particle-opacity="0.7" :particles-number="100" shape-type="circle"
			:particle-size="4" lines-color="#f4f4f4" :lines-width="1" :line-linked="true" :line-opacity="0.4"
			:lines-distance="150" :move-speed="3" :hover-effect="true" hover-mode="grab" :click-effect="true"
			click-mode="push" class="particles-js">
		</vue-particles> -->
		<transition>
			<router-view />
		</transition>
		<loading v-if="this.$store.state.isShow"></loading>
		
	</div>
</template>

<script>
import Loading from "./components/loading/loading.vue";
export default {
	data() {
		return {
			transitionName: 'slide-left'
		}
	},
	name: 'App',
	components: {
		Loading
	},

	watch: { //使用watch 监听$router的变化
		$route(to, from) {
			//如果to索引大于from索引,判断为前进状态,反之则为后退状态
			if (to.meta.index > from.meta.index) {
				//设置动画名称
				this.transitionName = 'slide-left';
			} else {
				this.transitionName = 'slide-right';
			}
		}
	},
}
</script>

<style>
.particles-js {
	width: 100%;
	height: calc(100% - 100px);
	position: fixed;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
	will-change: transform;
	transition: all 500ms;
	position: absolute;
}

.slide-right-enter {
	opacity: 0;
	transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
	opacity: 0;
	transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
	opacity: 0;
	transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
	opacity: 0;
	transform: translate3d(-100%, 0, 0);
}


</style>
