<template>
  <div v-title data-title="用户 | 信息查看">
    <transition appear name="animate__animated animate__bounce" enter-active-class="animate__zoomInRight">
    <el-card>
      <el-descriptions class="margin-top" title="简介" :column="2" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-picture-outline"></i>
            头像
          </template>
          <img class="img" :src="defForm.avatar" />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-custom"></i>
            昵称
          </template>
          {{ defForm.nickName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-odometer"></i>
            年龄
          </template>
          {{ defForm.age }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-male"></i>
            <i class="el-icon-female"></i>
            性别
          </template>
          <el-tag size="small">{{ sex }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-message"></i>
            邮箱Email
          </template>
          {{ defForm.email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            手机号码
          </template>
          {{ defForm.mobilePhoneNumber }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            地区
          </template>
          {{ defForm.area }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            职业
          </template>
          {{ defForm.work }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-basketball"></i>
            兴趣爱好
          </template>
          {{ defForm.hobby }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-magic-stick"></i>
            个性签名
          </template>
          {{ defForm.design }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            注册日期
          </template>
          {{ defForm.createDate }}
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </transition>
  </div>
</template>

<script>
import token from "@/store/token.js"
import { detail } from "@/api/user"
export default {
  name: "Info",
  data() {
    return {
      defForm: {
        avatar: String,
        account: String,
        email: String,
        age: Number,
        mobilePhoneNumber: String,
        area: String,
        createDate: Number,
        nickname: String,
        work: String,
        hobby: String,
        design: String,
      },

      sex: '',

    };
  },

  created() {
    const user = JSON.parse(localStorage.getItem('user'))
    detail(user.id).then(data => {
      this.defForm = data.data
      this.sex =  this.defForm .sex == 1 ? "男" : "女"
    })

    // this.defForm=token.getUser()
  
  }

};
</script>

<style scoped>
.img {
  width: 80px;
  height: 80px;
}
</style>
