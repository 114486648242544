
   

<template>
    <div class="card">
        
        <div class="dateBox">
            <div class="time">{{ time }}</div>
            
            <div class="date">{{ date }}</div>
        </div>
     
        <!-- <div class="gx">
            <img src="@/assets/image/xin.gif">       
        </div> -->
       
       
    </div>

</template>

<script>

export default {

    name: "AboutMe",
    data() {
        return {
            date: null,
            time: null,
        }
    },

    mounted() {
        const clock = setInterval(() => {
            this.date = getToday().date;
            this.time = getToday().time;
        }, 1000);
        // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
        this.$once("hook:beforeDestroy", () => {
            clearInterval(clock);
        });
    },

}
function getToday() {
    var datas = new Date();
    var on1 = "/";
    var on2 = ":";
    var onY = datas.getFullYear(); //年
    var onM = datas.getMonth() + 1; //月
    var onD = datas.getDate(); //日
    var onS = datas.getHours(); //时
    var onF = datas.getMinutes(); //分
    var onN = datas.getSeconds(); //秒

    if (onM >= 1 && onM <= 9) {
        //月
        onM = "0" + onM;
    }
    if (onD >= 1 && onD <= 9) {
        //日
        onD = "0" + onD;
    }
    if (onS >= 0 && onS <= 9) {
        //时
        onS = "0" + onS;
    }
    if (onF >= 0 && onF <= 9) {
        //分
        onF = "0" + onF;
    }
    if (onN >= 0 && onN <= 9) {
        //秒
        onN = "0" + onN;
    }

    return {
        date: onY + on1 + onM + on1 + onD,
        time: onS + on2 + onF + on2 + onN,
    };
}
</script>

<style  scoped>
.dateBox {
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    height: 90px;
}
.gx{
    width: 100%;
    height: 100%;
    position: relative;
    top: 10px
}
.date {
    font-size: 22px;
    color: #8ac9ff;
    margin-left: 15px;
}

.time {
    font-size: 35px;
    color: #df0c0c;
}

</style> 