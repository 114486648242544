<template>
    <div v-title data-title="游戏列表">
        <Header></Header>
        <article>
            <transition appear name="animate__animated animate__bounce" enter-active-class="animate__fadeInRight">
                <div class="lbox">
                    <div class="box" v-for="item in gameList">
                        <div class="gameButton">
                            <div class="ganmName">游戏名：<span style="color: blue;">{{ item.gameName }}</span>
                            </div>
                            <div class="ganmName">描述：<span style="color: blue;">{{ item.description }}</span>
                            </div>
                            <el-button @click="goTancheshe(item.gameHrefLocation)">启动游戏</el-button>
                        </div>
                    </div>
                </div>
            </transition>

            <transition appear name="animate__animated animate__bounce" enter-active-class="animate__fadeInTopLeft">
                <div class="rbox">
                    <Order></Order>
                    <Tag></Tag>
                    <FriendLink></FriendLink>
                </div>
            </transition>
        </article>


    </div>
</template>

<script>
import '@/assets/css/base.css'
import '@/assets/css/m.css'
import Header from "../../components/front/Header";
import Order from "../../components/front/Order";
import FriendLink from "../../components/front/FriendLink";
import Footer from "../../components/front/Footer";
import Tag from "../../components/front/Tag";
import { getGame } from "@/api/game";
import store from '@/store/index.js'
export default {
    name: "List",
    data() {
        return {
            channelId: 0,
            channel: {},
            xx: true,
            gameList: {},

        }
    },
    components: {
        Header, Tag, Footer, FriendLink, Order
    },
    mounted() {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user == undefined) {
            this.$message.error('登陆后即可畅玩所有游戏哦!!');
            this.$router.replace('/userlogin')
        }
    },
    created() {
        getGame().then(data => {
            this.gameList = data.data
        })
    },
    methods: {
        goTancheshe(href) {
            window.open(href, '_blank');
            // window.location.href = href
        },
        handleCurrentChange(val) {
            let param = this.article
            this.pageNo = val
            param.page = this.pageNo
            this.list(param)
        },
    }
}
</script>

<style scoped>
.bigBox {
    /* float: right; */
    overflow: hidden;
    margin-top: 25px;
    opacity: 0.9;
    width: 97.5%;
}

.box {
    width: 1024px;
    flex: 1;
    /* 小框之间的间隔 */
    padding: 20px;
    line-height: 10px;
    /* 小框内的填充 */
    border: 1px solid #000;
    background-color: #c9ced5;
    height: 50px;
    opacity: 0.9;
    margin-bottom: 20px;
    border-radius: 20px;
    /* 小框边框 */
}

.gameButton {
    /* position: relative; */
    /* float: right; */
    /* margin: 5px auto; */
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
}

.ganmName {
    /* text-align: left; */
    /* margin: 0 auto; */
    /* align-items: center; */
    /* float: left; */
    width: 200px;
    line-height: 50px;
    display: block;
}
</style>