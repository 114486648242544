<template>
  <div>
    <transition appear name="animate__animated animate__bounce" enter-active-class="animate__zoomIn">
      <div class="PersonTop">
        <div class="PersonTop_img">
          <img :src="form.avatar" />
        </div>
        <div class="PersonTop_text">
          <div class="user_text">
            <div class="user_name">
              <span> {{ form.nickName }} </span>
            </div>
            <div class="user-v">
              <img src="@/assets/images/v.png" class="user-v-img" style="display:inline-block" />
              <span class="user-v-font">优质媒体作者</span>
            </div>
            <div class="user_qianming">
              <span>签名: {{ form.design }}</span>
            </div>
            <div class="user_anniu">
              <el-button class="el-icon-edit" v-if="this.$route.params.id === this.$store.state.id" type="primary"
                size="medium" plain @click="edit">编辑</el-button>
              <el-button v-else @click="follow" type="primary" size="medium" icon="el-icon-check" v-text="isfollowid.indexOf(this.$route.params.id) > -1
                ? '已关注'
                : '关注'
                "></el-button>
            </div>
          </div>
          <div class="user_num">
           

            <!-- <div style="cursor: pointer" @click="myfan">
            <div class="num_number">{{ form.fanCounts }}</div>
            <span class="num_text">粉丝</span>
          </div>
          <div style="cursor: pointer" @click="myfollow">
            <div class="num_number">{{ form.followCounts }}</div>
            <span class="num_text">关注</span>
          </div>
          <div>
            <div class="num_number">{{ form.goodCounts }}</div>
            <span class="num_text">获赞</span>
          </div> -->
          </div>
         </div>
        </div>
       
    </transition>
    <div class="person_body">
      <transition appear name="animate__animated animate__bounce" enter-active-class="animate__zoomInLeft">
        <div class="person_body_left">
          <el-card class="box-card" :body-style="{ padding: '0px' }">
            <div slot="header" class="clearfix">
              <span class="person_body_list" style="border-bottom: none">个人中心</span>
            </div>
            <!-- <div
            class="person_body_list"
            v-for="(item, index) in person_body_list"
            :key="index"
          >
            <router-link :to="{ name: item.name, params: item.params }">{{
              item.label
            }}</router-link>
          </div> -->
            <el-menu router active-text-color="#00c3ff" class="el-menu-vertical-demo">
              <el-menu-item index="info" @click="goUrl(1)">
                <i class="el-icon-user"></i>
                <span slot="title">个人简介</span>
              </el-menu-item>

              <el-menu-item @click="goUrl(2)">
                <i class="el-icon-edit-outline"></i>
                <span slot="title">发布文章</span>
              </el-menu-item>

              <el-menu-item index="index" @click="goUrl(3)">
                <i class="el-icon-edit-outline"></i>
                <span slot="title">我的已发</span>
              </el-menu-item>

              <el-menu-item index="mycollect" @click="goUrl(4)">
                <i class="el-icon-document"></i>
                <span slot="title">收藏</span>
              </el-menu-item>
              <!-- <el-menu-item
              index="myfan"
              :route="{ name: 'myfan' }"
            >
              <i class="el-icon-tableware"></i>
              <span slot="title">粉丝</span>
            </el-menu-item>
            <el-menu-item
              index="myfollow"
              :route="{ name: 'myfollow' }"
            >
              <i class="el-icon-circle-plus-outline"></i>
              <span slot="title">关注</span>
            </el-menu-item> -->

              <el-menu-item index="/" :router="{ name: 'fIndex' }">
                <i class="el-icon-switch-button"></i>
                <span slot="title">返回主页</span>
              </el-menu-item>
            </el-menu>
          </el-card>
        </div>
      </transition>
      <transition appear name="animate__animated animate__bounce" enter-active-class="animate__zoomInRight">
        <div class="person_body_right">
          <router-view></router-view>
        </div>
      </transition>
    </div>
    <personal-dia ref="dia" />

  </div>
</template>

<script>
import token from "@/store/token.js"
import {
  myFollow,

  followAndFanCount,
} from "@/api/follow.js";
// import { mygoodCount } from "@/api/good";
import PersonalDia from "./PersonalDia.vue";

export default {
  components: { PersonalDia },
  name: "Personal",
  data() {
    return {
      form: {
        avatar: "",
        nickName: "",
        v: 1,
        design: "",
        followCounts: "",
        fanCounts: "",
        goodCounts: "",

        isfollow: true,
        followData: {
          fanId: "",
          followId: "",
        },
        isfollowid: [],
      }

    };
  },

  created() {
    this.form = token.getUser()
  },

  methods: {
    goUrl(num) {
      switch (num) {
        case 1: this.$router.replace('personinfo')
          break;
        case 2: this.$router.replace('myarticle')
          break;
        case 3: this.$router.replace('articlePub')
          break;
        case 4: this.$router.replace('mycollect')
          break;
      }
    },
    load() {
      myFollow(this.$store.state.id)
        .then((res) => {
          res.data.forEach((res) => {
            this.isfollowid.push(res.id);
          });
        })
        .catch((err) => {
          this.$message.error(err);
        });

      followAndFanCount(this.$route.params.id)
        .then((res) => {
          this.followCounts = res.data.followCounts;
          this.fanCounts = res.data.fanCounts;
        })
        .catch((err) => {
          this.$message.error(err);
        });

      mygoodCount(this.$route.params.id)
        .then((res) => {
          this.goodCounts = res.data.goodCounts;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    myfan() {
      this.$router.push({
        path: `/myfan`,
      });
    },
    myfollow() {
      this.$router.push({
        path: `/myfollow`,
      });
    },

    edit() {
      this.$refs.dia.open();
    },

  },
};
</script>

<style scoped>
.me-video-player {
  background-color: transparent;
  width: 100%;
  height: 100%;
  object-fit: fill;
  display: block;
  position: fixed;
  left: 0;
  z-index: 0;
  top: 0;
}

.PersonTop {
  width: 1200px;
  height: 140px;
  padding-top: 20px;
  background-color: white;
  margin-top: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  border-radius: 5px;
  opacity: 0.8;
}

.PersonTop_img {
  width: 150px;
  height: 120px;
  background-color: #8c939d;
  margin-right: 24px;
  margin-left: 20px;
  overflow: hidden;
  border-radius: 20px;
}

.PersonTop_img img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.PersonTop_text {
  height: 120px;
  width: 880px;
  display: flex;
}

.user_text {
  width: 60%;
  height: 100%;
  line-height: 30px;
}

.user_name {
  font-weight: bold;
}

.user-v {
  margin-bottom: -5px;
}

.user-v-img {
  width: 15px;
  height: 15px;
}

.user-v-font {
  font-size: 15px;
  color: #00c3ff;
  margin-left: 8px;
}

.user_qianming {
  font-size: 14px;
  color: #999;
}

.user_num {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  left: 18%;
  position: relative;
}

.user_num>div {
  text-align: center;
  border-right: 1px dotted #999;
  box-sizing: border-box;
  width: 80px;
  height: 40px;
  line-height: 20px;

}

.num_text {
  color: #999;
}

.num_number {
  font-size: 20px;
  color: #333;
}

.el-menu-item>span {
  font-size: 16px;
  color: #999;
}

/*下面部分样式*/
.person_body {
  width: 1200px;
  margin-top: 210px;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  opacity: 0.8;
}

.person_body_left {
  width: 20%;
  height: 100%;
  border-radius: 5px;
  margin-right: 3%;
  text-align: center;
}

.person_body_list {
  width: 100%;
  height: 50px;
  margin-top: 25px;
  font-size: 22px;
  border-bottom: 1px solid #f0f0f0;
  background-image: -webkit-linear-gradient(left,
      rgb(42, 134, 141),
      #e9e625dc 20%,
      #3498db 40%,
      #e74c3c 60%,
      #09ff009a 80%,
      rgba(82, 196, 204, 0.281) 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-background-size: 200% 100%;
  -webkit-animation: masked-animation 4s linear infinite;
}

.el-menu-item {
  margin-top: 22px;
}

.person_body_right {
  width: 77%;
  height: 100%;
  border-radius: 5px;
  background-color: #c9ced5;

}

.box-card {
  height: 550px;
}

/*ui样式*/
.el-button {
  width: 84px;
}


</style>
