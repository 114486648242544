import { render, staticRenderFns } from "./ChannelDetail.vue?vue&type=template&id=85e93636&scoped=true&"
import script from "./ChannelDetail.vue?vue&type=script&lang=js&"
export * from "./ChannelDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85e93636",
  null
  
)

export default component.exports