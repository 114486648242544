let token = {
	
	set(token) {
		localStorage.setItem('token', token)
	},

	setAdmin(adminToken){
		localStorage.setItem('adminToken', adminToken)
	},
	
	get() {
		return localStorage.getItem('token')
	},

	getAdmin() {
		return localStorage.getItem('adminToken')
	},

	setUser(user) {
		localStorage.setItem('user', JSON.stringify(user))
	},

	getUser() {
		if (localStorage.getItem('user')) {
			return JSON.parse(localStorage.getItem('user'))
		}
	},

	clear() {
		localStorage.removeItem('token')
		localStorage.removeItem('user')
	}
}
export default token
