<template>
	<div>
		<transition appear name="animate__animated animate__bounce" enter-active-class="animate__fadeInDownBig">
		<!--top begin-->
		<header id="header">
			
			<div class="navbox">
				<h2 id="mnavh"><span class="navicon"></span></h2>
				<div class="logo"><a href="/">网络日记</a></div>
				
					<nav>
						<ul id="starlist">
							<li><a href="/">首页</a></li>
							<li v-for="(item, index) in channels " :key="index" :class="{ menu: item.children }">
								<router-link v-if="item.single" :to='"/channel_detail/" + item.id'>{{ item.name
									}}</router-link>
								<router-link v-else :to='"/list/" + item.id'>{{ item.name }}</router-link>
								<ul class="sub" v-if="item.children">
									<li v-for="(sub, sindex) in item.children" :key="sindex">
										<router-link v-if="sub.single" :to='"/channel_detail/" + sub.id'>{{ sub.name }}
										</router-link>
										<router-link v-else :to='"/list/" + sub.id'>{{ sub.name }}</router-link>
									</li>
								</ul>
							</li>
							<li v-if="!isLogin">
								<router-link to="/userlogin" @click="login()">用户登录</router-link>
							</li>
							<li style="padding-left: 10px;padding-right: 10px;" v-if="isLogin">
								<el-dropdown @command="handleCommand">
									<span style="color: #ffffff;">
										<el-avatar size="small" v-if="userInfo.avatar != undefined"
											:src="userInfo.avatar" style="position: relative;top:8px;">
										</el-avatar>
										{{ userInfo.nickName == null ? '匿名' : userInfo.nickName }}
										<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item command="userCenter">个人中心</el-dropdown-item>
										<el-dropdown-item command="game">游戏中心</el-dropdown-item>
										<el-dropdown-item command="userAdmin"
											v-if="userInfo.admin === '1' || userInfo.admin === '2'">后台管理
										</el-dropdown-item>
										<el-dropdown-item command="exit">退出登录</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</li>
						</ul>
					</nav>
				
			</div>
			
		</header>
	</transition>
	</div>
	<!--top end-->
</template>

<script>
import 'animate.css'
import store from '@/store/index.js'
import { queryByPos } from '@/api/front'
import { detail, logout } from "@/api/user"
import $ from 'jquery'

export default {
	name: "Header",
	data() {
		return {
			channels: [],
			userInfo: {},
			avatar: 'https://blogs.ziywm.work/cms/upload/5289cae9-43db-47e2-9a91-c3c5dd6ded87.png',
			isLogin: false,
		}
	},
	mounted() {
		queryByPos('a').then(data => {
			this.channels = data.data
		}).catch(error => {

		})
		$("#mnavh").click(function () {
			$("#starlist").toggle();
			$("#mnavh").toggleClass("open");
		})
	},
	created() {
		const user = JSON.parse(localStorage.getItem('user'))
		if (user) {
			detail(user.id).then(data => {
				this.userInfo = data.data
				this.isLogin = true
			})
		} else {
			this.isLogin = false
			this.userInfo = {}
		}
	},
	methods: {
		handleCommand(command) {
			if (command == 'userCenter') {
				this.$router.push('/Personinfo')
			}
			if (command == 'userAdmin') {
				this.toUserAdminBlank()
			}
			if (command == 'game') {
				this.$router.push('/Game')
			}

			if (command == 'exit') {
				this.isLogin = false
				this.$store.commit('logout')
				this.$message.success("退出成功")
			}

		},
	
		// 新页面跳转后台管理
		toUserAdminBlank() {
			let routeUrl = this.$router.resolve({
				name: 'User'
			})
			window.open(routeUrl.href, '_blank') //关键在此
		},

	}
}
</script>

<style scoped></style>
