<template>
	<div>
		<transition appear name="animate__animated animate__bounce" enter-active-class="animate__rotateInUpRight">
		
		<div class="login-register" v-title data-title="登录注册" style="height: 100vh; width: 100%;">

			<div class="contain">
				<div class="big-box" :class="{ active: isLogin }">
					<!-- 注册 -->
					<div class="big-contain" key="bigContainRegister" v-if="isLogin">
						<div class="btitle">创建账户</div>
						<!-- <div class="bform"> -->
						<el-form ref="registerForm" :model="form" :rules="rules" class="bform">
							<el-form-item prop="email">
								<el-input type="email" prefix-icon="el-icon-user-solid" placeholder="请输入邮箱"
									v-model="form.email" />
							</el-form-item>
							<el-form-item prop="password">
								<el-input show-password prefix-icon="el-icon-lock" placeholder="请输入密码"
									v-model="form.password" />
							</el-form-item>
							<el-form-item prop="userpwd1">
								<el-input show-password prefix-icon="el-icon-lock" placeholder="请再次输入密码"
									v-model="form.userpwd1" />
							</el-form-item>
							<el-form-item prop="nickName">
								<el-input type="text" prefix-icon="el-icon-user" placeholder="请输入昵称"
									v-model="form.nickName" />
							</el-form-item>

							<el-form-item prop="verifyCode">
								<div class="bind_code margin_top">
									<el-input class="bind_code_input code" prefix-icon="el-icon-key" icon="el-icon-lock"
										type="text" placeholder="请输入验证码" v-model="form.verifyCode" />
									<el-button class="bind_code_gain" style="width: 20%;" :disabled="two"
										:loading="loading" @click="send">{{ btnText }}</el-button>

								</div>
							</el-form-item>

						</el-form>
						<!-- </div> -->
					</div>
					<!-- 登录 -->

					<div class="big-contain" key="bigContainLogin" v-else>
						<div class="btitle">账户登录</div>
						<!-- <div class="bform" > -->
						<el-form ref="form" :model="form" :rules="rules" class="bform">
							<el-form-item prop="email">
								<el-input type="email" prefix-icon="el-icon-user-solid" placeholder="请输入邮箱"
									v-model="form.email" />
							</el-form-item>
							<el-form-item prop="password">
								<el-input show-password prefix-icon="el-icon-lock" placeholder="请输入密码"
									v-model="form.password" />
							</el-form-item>
						</el-form>
						<!-- </div> -->
						<div class="tiao">
							<router-link to="/login"
								style="display: inline-block;text-align: center;">管理员登录</router-link>
							<el-button class="bbutton" :loading="loading" native-type="submit"
								@click="login">登录</el-button>
						</div>
					</div>
				</div>
				<!-- 管理 登录页 -->
				<div class="small-box" :class="{ active: isLogin }">
					<div class="small-contain" key="smallContainRegister" v-if="isLogin">
						<div class="stitle" style="color: red;">知识改变未来!</div>
						<br />

						<el-button class="sbutton" @click.prevent="changeType">登录</el-button>
						<br />
						<el-button class="sbutton" :loading="loading" @click="register()">注册</el-button>
					</div>
					<!-- 注册页 -->
					<div class="small-contain" key="smallContainLogin" v-else>
						<div class="stitle" style="color: red;">态度决定命运</div>
						<br />
						<el-button class="sbutton" @click="changeType">注册</el-button>
						<br />
						<el-button class="sbutton" @click="goForget">忘记密码</el-button>
						<br />
						<el-button class="sbutton" @click="gohome">回到首页</el-button>
					</div>
				</div>
			</div>

		</div>
		</transition>
	</div>
</template>

<script>
import token from "@/store/token.js"
import { userCreate, send } from '../../api/user'
export default {
	name: 'login-register',
	data() {
		var validatePass2 = (rule, value, callback) => {
			if (value !== this.form.password) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			isLogin: false,
			existed: false,
			btnText: '发送验证码',
			form: {
				email: '',
				password: '',
				userpwd1: '',
				nickName: '',
				verifyCode: '',
				status: 'T',
				admin: '0'
			},
			two: false,
			message: '',
			loading: false,
			rules: {
				email: [{
					required: true,
					message: '请输入邮箱'
				},
				{
					type: 'email',
					message: '请输入正确的邮箱地址',
					trigger: ['blur', 'change']
				}
				],
				password: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur',
				},
				{
					min: 6, message: '密码长度在 6 位以上', trigger: 'blur'
				}
				],
				userpwd1: [{
					validator: validatePass2,
					trigger: 'blur'
				}],
				nickName: [{
					required: true,
					message: '请输入昵称',
					trigger: 'blur'
				}],
				verifyCode: [{
					required: true,
					message: '请输入验证码',
					trigger: 'blur'
				}]
			}
		}
	},
	methods: {
		changeType() {
			this.isLogin = !this.isLogin
		},
		gohome() {
			this.$router.push("/")
		},
		goForget() {
			this.$router.replace("forget")
		},
		login() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.loading = true
					this.$store.dispatch('userLogin', this.form).then(() => {
						this.$message.success('登录成功')
						this.$router.push('/')
					}).catch(error => {
						console.log(error)
						this.loading = false
						this.$message.error(error)
					})
				}
			})
		},
		register() {
			if (this.form.password !== this.form.userpwd1) {
				this.$message.error('两次密码不一致')
			} else {
				this.$refs['registerForm'].validate(valid => {
					if (valid) {
						this.loading = true
						userCreate(this.form).then(data => {
							this.$message.success(data.message)
							this.isLogin = fasle
							this.login()
						}).catch(error => {
							this.isLogin = true
							this.loading = false
							this.$message.error(error)
						})
					}
				})
			}
		},
		send() {
			if (this.form.email === "") {
				this.$message.error('请先输入邮箱!!!')
			} else {
				this.loading = true
				send(this.form.email).then(data => {
					this.loading = false
					this.$message.success(data.message)
					this.two = true;
					this.btnText = "请稍候...";
					setTimeout(() => {
						this.doLoop(60);
					}, 500);
				}).catch(error => {
					this.loading = false
					this.$message.error(error)
				})
			}
		},
		doLoop: function (seconds) {
			seconds = seconds ? seconds : 60;
			this.btnText = seconds + "s后获取";
			// this.code = code
			let countdown = setInterval(() => {
				if (seconds > 0) {
					this.btnText = seconds + "s后获取";
					--seconds;
				} else {
					this.btnText = "获取验证码";
					this.two = false;
					clearInterval(countdown);
				}
			}, 1000);

		}
	}
}
</script>

<style scoped="scoped" lang="scss">
.login-register {
	width: 100%;
	height: 100%;
	/* box-sizing: border-box; */

}

/deep/ .el-input .el-input__clear {
	color: #C0C4CC;
	font-size: 14px;
	cursor: pointer;
	transition: color .2s cubic-bezier(.645, .045, .355, 1);
	left: -125px;
	/* left: 14px; */
	position: relative;
}

.bind_code {
	position: relative;

	.el-input__inner {
		width: 328px;
		height: 44px;
		background: #f7f7f7;
		border-radius: 4px;
		border: 1px solid #e4e4e4;
		outline: none;
		padding: 0 100px 0 10px;
	}

	.code /deep/.el-input__suffix {
		right: 97px;
	}

	.el-button {
		border: 0;
		background: none;
		padding: 0;
		border-radius: 0;
	}

	.pic {
		width: 80px;
		height: 25px;
		border-left: none !important;
	}

	.bind_code_gain {
		position: absolute;
		top: calc(50% - 9px);
		right: 140px;
		font-size: 14px;
		font-family: MicrosoftYaHei;
		color: #20aee5;
		line-height: 18px;
		cursor: pointer;
		padding-left: 10px;
		border-left: 1px solid #d8d8d8;
	}

	.login-icon {
		width: 16px;
		height: 16px;
		position: absolute;
		top: 12px;
		left: 8px;

	}
}

/deep/ .el-input__inner {
	background-color: #ffffff;
	border-radius: 30px;
	border: 1px solid #DCDFE6;
	box-sizing: border-box;
	color: #606266;
	/* display: inline-block; */
	font-size: inherit;
	height: 30px;
	line-height: 40px;
	outline: 0;
	padding: 0 30px;
	transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
	width: 70%;
}

.el-form-item {
	width: 70%;
	height: 30px;
	border: none;
	outline: none;
	border-radius: 10px;
	padding-left: 3em;
	letter-spacing: 3px;
	margin-left: 60px;
}

.contain {
	width: 800px;
	height: 500px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 20px;
	box-shadow: 0 0 3px #f0f0f0,
		0 0 6px #f0f0f0;
}

.big-box {
	width: 70%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 30%;
	transform: translateX(0%);
	transition: all 1s;
}

.big-contain {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.tiao {
	position: relative;
	width: 60%;
	height: 50px;
}

.spann {

	margin: 0 auto;
	padding: 0;
}

.btitle {
	font-size: 1.5em;
	font-weight: bold;
	color: rgb(75, 224, 234);
	text-align: center;
}

.bform {
	width: 100%;
	height: 40%;
	padding: 2em 0;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	margin: 0 auto
}



.bform input {
	width: 50%;
	height: 30px;
	border: none;
	outline: none;
	border-radius: 10px;
	padding-left: 3em;
	letter-spacing: 3px;
	margin-top: -5px;
}

.bbutton {
	width: 40%;
	height: 40px;
	border-radius: 24px;
	border: none;
	outline: none;
	background-color: rgb(57, 167, 176);
	color: #1eff05;
	font-size: 0.9em;
	cursor: pointer;
	float: right;
}

/deep/ .el-form-item__error {
	color: #F56C6C;
	font-size: 12px;
	line-height: 1;
	padding-top: 4px;
	position: absolute;
	top: 85%;
	left: 0px;
}

a {
	width: 35%;

	border-radius: 24px;
	border: none;
	outline: none;
	background-color: rgb(176, 5, 0);
	color: #fff;
	font-size: 0.9em;
	cursor: pointer;
	padding: 10px;
	float: left;


}

.small-box {
	width: 30%;
	height: 100%;
	border: 1px;
	border-color: aqua;
	position: absolute;
	top: 0;
	left: 0;
	transform: translateX(0%);
	transition: all 1s;
	border-top-left-radius: inherit;
	border-bottom-left-radius: inherit;

}

.small-contain {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.stitle {
	font-size: 1.5em;
	font-weight: bold;
	color: #fff;
}

.scontent {
	font-size: 0.8em;
	color: #fff;
	text-align: center;
	padding: 2em 4em;
	line-height: 1.7em;
}

.sbutton {
	width: 60%;
	height: 40px;
	border-radius: 24px;
	border: 1px solid #fff;
	outline: none;
	background-color: transparent;
	color: #fff;
	font-size: 0.9em;
	cursor: pointer;
}

.big-box.active {
	left: 0;
	transition: all 0.5s;

}

.small-box.active {
	left: 100%;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: inherit;
	border-bottom-right-radius: inherit;
	transform: translateX(-100%);
	transition: all 1s;
}

.el-button {
	display: inline-block;
	line-height: 1;
	white-space: nowrap;
	cursor: pointer;
	background: #bcff3e;
	border: 1px solid #DCDFE6;
	color: #606266;
	text-align: center;
	box-sizing: border-box;
	outline: 0;
	margin: 0;
	transition: .1s;
	font-weight: 100;
	padding: 2px 2px;
	font-size: 14px;
	border-radius: 25px;
}
</style>
