import axiosInstall from './axiosInstance';

export function create(reply) {
    return axiosInstall.post('reply/create',reply)
}


export function del(id) {
    return axiosInstall.post('reply/delete?id='+id)
}


export function updateReply(reply) {
    return axiosInstall.post('reply/update',reply)
}


export function queryReply(reply) {
    return axiosInstall.post('reply/query',reply)
}




export function count(reply) {
    return axiosInstall.post('/front/count',reply)
}

export function detail(id) {
    return axiosInstall.post('reply/detail',{
        id: id
    })
}