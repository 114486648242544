import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'
//element ui
import Element from 'element-ui'
import  'element-ui/lib/theme-chalk/index.css'
import utils from './utils'
// 加载动画
import loading from 'v-loading-plugin'
// 动态粒子
import VueParticles from 'vue-particles' 

//markdown
import VMdpreview from '@kangc/v-md-editor/lib/preview'
import   '@kangc/v-md-editor/lib/style/preview.css'
import githubTheme from  '@kangc/v-md-editor/lib/theme/github'
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import VueKinesis from 'vue-kinesis'
 
//swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'

VueMarkdownEditor.use(vuepressTheme);
Vue.use(VueKinesis)
Vue.use(VueParticles)
VMdpreview.use(githubTheme)
Vue.use(VMdpreview);
Vue.use(loading)
Vue.use(VueAwesomeSwiper,)
Vue.use(VueMarkdownEditor);
axios.default.withCredentials = false
axios.default.crossOrigin = true
Vue.use(Element);
Vue.config.productionTip = false
Vue.prototype.utils=utils
Vue.prototype.$axios = axios
Vue.directive('title',{
  inserted (dom){
    document.title = dom.dataset.title
  }
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
