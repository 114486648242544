<template>
  <div class="whitebg bloglist" v-title data-title="用户 | 查看收藏">
    <ul>
      <li v-for="(item, index) in articles" :key="index">
        <h3 class="blogtitle"><router-link :to='"/detail/" + item.article.id' target="_blank">{{ item.article.title
        }}</router-link>
        </h3>
        <span class="blogpic imgscale"><i><router-link :to='"/detail/" + item.article.id'>{{ item.channel.name
        }}</router-link></i>
          <router-link :to='"/detail/" + item.article.id'>
            <img v-if="item.article.titleImg == null || item.article.titleImg == ''" width="130px" height="120px" alt="">
            <img v-else :src="item.article.titleImg" height="120px" alt="">
          </router-link>
        </span>
        <p class="blogtext">{{ item.article.summary }} </p>
        <p class="bloginfo">
          <i class="avatar"><img :src="item.user.avatar"></i>
          <span>{{ item.user.nickName }}</span><span>{{ item.article.createDate }}</span>
        </p>
        <router-link :to='"/detail/" + item.article.id' class="viewmore"> 阅读更多</router-link>
      </li>
    </ul>
    <div>
      <el-pagination background layout="total, prev, pager, next, jumper" @current-change="handleCurrentChange"
        :current-page="pageNo" :page-size="7" :total="total">
      </el-pagination>
    </div>
    <el-empty v-if="articles.length == 0" :image-size="250" description="暂未收藏任何文章额"></el-empty>

  </div>
</template>

<script>
import { query } from "@/api/collect.js";
import store from '@/store/index.js'
// import ArticleItem from '../../components/article/ArticleItem.vue';
export default {
  // components: { ArticleItem },
  name: "MyCollect",
  data() {
    return {
      articles: [],
      pageNo: 1,
      total: 0
    };
  },
  methods: {
    handleCurrentChange(val) {
      let param = this.article
      this.pageNo = val
      param.page = this.pageNo
      this.list(param)
    }
  },
  created() {
    const userId = store.state.user.id
    query(userId).then(data => {
      if (data) {
        this.articles = data.list
        this.total = data.total
      }
    }).catch(error => {
      this.$message.error(data.message)
    })
  },
};
</script>

<style>
.el-card {
  border-radius: 0;
}

.el-card:not(:first-child) {
  margin-top: 5px;

}

.myart1 {
  line-height: 30px;
}
</style>
