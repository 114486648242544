<template>
	<div class="loading">
		
	</div>
</template>

<script>
	export default {};
</script>

<style scoped>
	.loading {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(255, 255, 255, 0.795);
		background-image: url("../../assets/loading.gif");
		background-repeat: no-repeat, repeat-y;
		background-position: center;
	}
</style>
