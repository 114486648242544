import axiosInstall from './axiosInstance';

export function login(userName, password) {
    return axiosInstall.post('login',{
        userName: userName,
        password:password
    })
}

export function userLogin(email, password) {
    return axiosInstall.post('front/user/userLogin',{
        email: email,
        password:password
    })
}

export function logOut() {
    return axiosInstall.post('goOut')  
}   

export function create(user) {
    return axiosInstall.post('user/create',user)
}
export function userCreate(user) {
    return axiosInstall.post('front/user/userCreate',user)
}

export function del(id) {
    return axiosInstall.post('user/delete?id='+id)
}

export function update(user) {
    return axiosInstall.post('user/update',user)
}



export function query(user) {
    return axiosInstall.post('user/query',user)
}


export function detail(id) {
    return axiosInstall.get('user/detail?id='+id)
}

export function send(email){
    return axiosInstall.post('mail/send',{
        email:email
    })
}
export function sendCode(email){
    return axiosInstall.post('mail/sendEmail',{
        email:email
    })
}
export function updateBE(user){
    return axiosInstall.post('front/user/updateBE',user)
}