<template>
	<div class="guoshun-form">
		<el-row :gutter="15">
			<el-form ref="formData" :model="formData" :rules="rules" size="medium" label-width="100px">
				<el-col :span="24">
					<el-form-item prop="content">
						<el-input type="textarea" :rows="8" v-model="formData.content" placeholder="平等交流，和谐发言" height=50px></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="15" style="float: right;">
					<el-form-item style="float: right;">
						<el-button type="primary" style="height: 43px;margin-bottom:20px;font-size: 17px"
							:loading="loading" @click="submitForm">立即评论</el-button>
					</el-form-item>
				</el-col>
			</el-form>
		</el-row>
	</div>
</template>

<script>
	import {
		create
	} from "../../api/comment";

	import store from '@/store/index.js'
	export default {
		name: "Form",
		data() {
			return {
				userInfo: {},
				formData: {
					author: '',
					avatar:'',
					content: '',
					email: '',
					articleId: ''
				},
				
				loading: false,
				rules: {
					content: [{
						required: true,
						message: '请输入评论内容或评论内容大于三个字',
						min:3,
						trigger: 'blur'
					}]

				},
				islogin: false
			}
		},
		created() {
			this.formData.articleId = this.$route.params.id
			const user = store.state.user
			this.formData.author = user.nickName
			this.formData.email = user.email
			this.formData.avatar = user.avatar
		},
		methods: {
			submitForm() {
				this.$refs['formData'].validate((valid) => {
						if (valid) {
							create(this.formData).then(data => {
								this.$message.success(data.message)
								
								this.$refs['formData'].resetFields()
							}).catch(error => {
								this.$message.error(error)
							})
						} else {
							return false;
						}
					
				});
			}
		}

	}
</script>

<style scoped>
	.guoshun-form {
		margin-left: -80px;
		margin-top: 20px;
	}
</style>
