import axiosInstall from './axiosInstance';

export function collectI(articleId,userId,isThrm) {
  return axiosInstall.post('collect/collectI',{
    articleId:articleId,
    userId:userId,
    isThrm:isThrm
  })
}

export function collectU(articleId,userId,isThrm) {
  return axiosInstall.post('collect/collectU',{
    articleId:articleId,
    userId:userId,
    isThrm:isThrm
  })
}
export function collectQ(articleId,userId,isThrm) {
  return axiosInstall.post('collect/collectU',{
    articleId:articleId,
    userId:userId,
    isThrm:isThrm
  })
}
export function query(userId){
  return axiosInstall.get('collect/detail?userId='+userId)
}

export function count(articleId){
  return axiosInstall.get('collect/getCount?articleId='+articleId)
}