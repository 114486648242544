<template>
     <transition 
                appear 
                name="animate__animated animate__bounce" 
                enter-active-class="animate__fadeInUp">
    <footer>
        <div class="box">
            <div class="wxbox">
                <ul>
                    <li><img src="@/assets/images/wx.png"><span>VX(备注来意!)</span></li>
                </ul>
            </div>
            <div class="endnav">
               
                <p><b>声明：</b></p>
                <p>1、本博客采用springBoot+Vue技术编写完成 </p>
                <p>2、本站站主Pluto、本站仅为学习采用，若有侵权，请联系删除!</p>
                <p>Copyright © 2022 Mr_Yu 互联网ICP备案：<a href="https://beian.miit.gov.cn/#/Integrated/index"  target="_blank" rel="noopener noreferrer">陇ICP备2022002299号-1</a> </p>
            
            </div>
           
            
        </div>
        <!-- <a href="#" target=".page-component__scroll .el-scrollbar__wrap"> -->
            
                <el-backtop></el-backtop>
			
        <!-- </a>  -->
        </footer>
     </transition>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>