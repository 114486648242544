import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router';
import { userLogin, login, logOut } from "../api/user";
import token from './token'

Vue.use(Vuex);


const store = new Vuex.Store({
    state: {
        token: token.get(),
        adminToken: token.getAdmin(),
        isShow: false,
        user: token.getUser(),
    },
    mutations: {
        setToken(state, paramToken) {
            state.token = paramToken
            token.set(paramToken)
        },
        setAdminToken(state, paramToken) {
            state.adminToken = paramToken
            token.setAdmin(paramToken)
        },
        logout() {
            logOut().then(data =>{
                token.clear()
                router.push("/")
            })  
        }
    },
    actions: {
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                login(user.userName, user.password).then(data => {
                    commit('setToken', data.data.token)
                    token.setUser(data.data.user)
                    //处理token 写入vuex状态管理
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        userLogin({ commit }, user) {
            return new Promise((resolve, reject) => {
                userLogin(user.email, user.password).then(data => {
                    commit('setToken', data.data.token)
                    token.setUser(data.data.user)
                    //处理token 写入vuex状态管理
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
});

export default store