<template>
	<div>
		<div class="whitebg">
			<div class="searchboxLeft">
				<h2 class="htitle">搜索</h2>
				<div>
					<el-form :inline="true" ref="queryForm" :model="queryForm" label-width="80px">
						<el-form-item>
							<el-input v-model="queryForm.title" style="width: 245px" placeholder="按文章标题查询"></el-input>
						</el-form-item>
						<el-form-item style="float: right;">
							<a href="#content" name="Search">
								<el-button icon="el-icon-search" style="width: 100%;" @click="search" type="primary">查询
								</el-button>
							</a>
						</el-form-item>
					</el-form>
					<el-col :span="12">
  </el-col>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		search
	} from '@/api/front'
	export default {
		name: "search",
		data() {
			return {
				queryForm: {
					name: '',
					userName: ''
				},
				articles: [],
				pageNo: 1,
			}
		},
		methods: {
			querySearch(queryString, cb) {
        var articles = this.articles;
        var results = queryString ? articles.filter(this.createFilter(queryString)) : articles;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (articles) => {
          return (articles.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
			search() {
				let param = this.queryForm
				this.pageNo = 1
				param.page = this.pageNo
				this.list(param)
			},
			list(param) {
				search(param).then(data => {
					this.articles = data.list
					this.$emit('childEven', this.articles)
					this.$emit('childEvenTitle', this.queryForm.title)
					this.total = data.total
				}).catch(error => {
					this.$message.error(error)
				})
			},
			handleSelect(item) {
      }
		},
		mounted() {
      // this.list()
    }
	}
</script>

<style scoped>
	 >>>  .el-form--inline .el-form-item__content {
		display: inline-block;
		vertical-align: top;
		width: 80px;
	}

	.el-button--primary {
		color: #FFF;
		background-color: #1fb9ff;
		border-color: #ef5f00;
	}

	.el-form--inline .el-form-item {
		display: inline-block;
		margin-right: 1px;
		vertical-align: top;
	}
</style>
