import { render, staticRenderFns } from "./Like.vue?vue&type=template&id=779b9341&scoped=true&"
import script from "./Like.vue?vue&type=script&lang=js&"
export * from "./Like.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "779b9341",
  null
  
)

export default component.exports