<template>
    <div class="guoshun-info" v-title data-title="博客后台 | 个人信息">
            <el-row :gutter="15">
                <el-form ref="defForm" :model="defForm" :rules="rules"  label-width="100px">
                    <el-col :span="24">
                        <!-- 用户头像 -->
                        <el-form-item label="用户头像">
                            <!-- 头衔缩略图 -->
                            <pan-thumb :image="image"/>
                            <!-- 文件上传按钮 -->
                            <el-button type="primary" icon="el-icon-upload" @click="imagecropperShow=true">更换头像
                            </el-button>
                            <!--
                              v-show：是否显示上传组件
                              :key：类似于id，如果一个页面多个图片上传控件，可以做区分
                              :url：后台上传的url地址
                              @close：关闭上传组件
                              @crop-upload-success：上传成功后的回调 -->
                            <image-cropper
                                    v-show="imagecropperShow"
                                    :width="300"
                                    :height="300"
                                    :key="imagecropperKey"
                                    :url="'user/upload'"
                                    field="file"
                                    @close="closeImage"
                                    @crop-upload-success="cropSuccess"
                            />
                            <!--@crop-upload-success="cropSuccess"-->

                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="用户名"  prop="userName">
                            <el-input v-model="defForm.userName"  placeholder="请输入用户名"  clearable :style="{width: '100%'}">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="昵称" prop="nickName">
                            <el-input v-model="defForm.nickName" placeholder="请输入昵称" clearable :style="{width: '100%'}">
                            </el-input>
                        </el-form-item>
						<el-form-item label="更改密码" prop="passWord">
						    <el-input v-model="defForm.password" type="password" placeholder="请输入密码" clearable :style="{width: '100%'}">
						    </el-input>
						</el-form-item>

                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="defForm.email" placeholder="请输入邮箱" clearable :style="{width: '100%'}">
                            </el-input>

                        </el-form-item>

                    </el-col>
                    <el-col :span="15">
                        <el-form-item >
                            <el-button type="primary" :loading="loading" @click="submitForm">保存</el-button>
                            <el-button @click="close">取消</el-button>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
    </div>
</template>

<script>
    import ImageCropper from '../../../components/ImageCropper'
    import token from '@/store/token'
    import {update} from "../../../api/user";
    import PanThumb from '@/components/PanThumb/index'
    export default {
        name: "Info",
        components: {PanThumb,ImageCropper},
        data() {
            return {
                defForm: {
                    id: '',
                    userName:'',
                    nickName: '',
					password: '',
                    avatar: '',
                    email: ''
                },

                loading: false,
                // 控制弹出框显示隐藏
                showDialog:false,
                imagecropperShow: false, // 是否显示上传组件
                imagecropperKey: 0, // 上传组件id
                image: 'https://blogs.ziywm.work:443/cms/upload/5289cae9-43db-47e2-9a91-c3c5dd6ded87.png"',
                
                rules: {
                    nickname: [{required: true, message: '请输入昵称', trigger: 'blur'}],
					password: [{required:true,message: '请输入密码',trigger: 'blur'}],
                    email: [{required: true, message: '请输入邮箱', trigger: 'blur'}],
                },
                
            }
        },
        created(){
            this.defForm=token.getUser()
            this.image=this.defForm.avatar
            if (this.defForm.avatar==null){
                this.image='https://blogs.ziywm.work:443/cms/upload/5289cae9-43db-47e2-9a91-c3c5dd6ded87.png"'
            }
        },
        methods:{
            onClose() {
                this.$refs['elForm'].resetFields()
                this.$emit('changeShow', 'false')
            },
            close() {
                // this.$emit('changeShow', 'false')
                this.$router.back()
            },
            submitForm(){
                this.$refs['defForm'].validate(valid=>{
                    if (valid) {
                        this.loading=true
                        update(this.defForm).then(data=>{
                            this.loading=false
                            this.$message.success(data.message)
                            //将用户的最新消息写入到localStorage
                            token.setUser(data.data)
                            this.defForm=data.data
                            token.clear()
                            this.$router.push('/login')
                            this.$message.warning("已修改信息，请重新登录!")
                        }).catch(error=>{
                            this.loading=true
                            this.$message.error(error)
                        })
                    }
                })
            },
            // 上传成功后的回调函数
            cropSuccess(data) {
                //使用该属性，保存上传之前的头像
                this.imagecropperShow = false
                this.defForm.avatar=this.image = data;
                // 上传成功后，重新打开上传组件时初始化组件，否则显示上一次的上传结果
                this.imagecropperKey = this.imagecropperKey + 1
            },
            // 关闭上传组件
            closeImage() {
                this.imagecropperShow = false
                // 上传失败后，重新打开上传组件时初始化组件，否则显示上一次的上传结果
                this.imagecropperKey = this.imagecropperKey + 1
            }
        }
    }
</script>

<style scoped>

    .guoshun-info{
        margin-left: 250px;
        margin-top: 30px;
        height: 550px;
    }

</style>