<template>
    <div v-title data-title="博客后台 | 添加游戏信息">
        <el-row :gutter="15">
            <el-form ref="formData" :model="formData" :rules="rules" size="medium" label-width="100px">
                <el-col :span="15">
                    <el-form-item label="游戏名称" prop="gameName">
                        <el-input v-model="formData.gameName" placeholder="输入游戏名称" clearable
                            :style="{ width: '100%' }"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="15">
                    <el-form-item label="描述" prop="description">
                        <el-input v-model="formData.description" placeholder="请输入描述" clearable
                            :style="{ width: '100%' }">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="15">
                    <el-form-item label="链接" prop="gameHrefLocation">
                        <el-input v-model="formData.gameHrefLocation" placeholder="请输入跳转地址" clearable
                            :style="{ width: '100%' }">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="15">
                    <el-form-item>
                        <el-button @click="$router.back()">取消</el-button>
                        <el-button type="primary" :loading="loading" @click="submitForm">保存</el-button>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>

<script>
import { create } from "@/api/game";
export default {
    name: "Add",
    data() {
        return {
            formData: {
                gameName: '',
                gameHrefLocation: '',
                description: ''
            },
            loading: false,
            rules: {
                gameName: [{ required: true, message: '请输入游戏名称', trigger: 'blur' }],
                gameHrefLocation: [{ required: true, message: '请输入跳转地址', trigger: 'blur' }],
                description: [{ required: true, message: '请输入游戏描述', trigger: 'blur' }],

            }
        }
    },
    methods: {
        submitForm(form) {
            this.$refs['formData'].validate(valid => {
                if (valid) {
                    this.loading = true
                    create(this.formData).then(data => {
                        this.loading = false
                        this.$message.success(data.message)
                        this.$refs['formData'].resetFields()

                        this.$router.push('/gameIndex')
                    }).catch(error => {
                        this.loading = true
                        this.$message.error(error)
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    width: 80px;
    position: relative;
    overflow: hidden;
}

.avatar-uploader:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 24px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
}

.avatar {
    width: 80px;
    height: 80px;
    display: block;
}
</style>