import axios from 'axios'
import store from '../store'
import token from '@/store/token'
import router from '@/router';
import {
	Message
} from 'element-ui'

const axiosIstance = axios.create({
	baseURL: 'https://blogs.ziywm.work/cms/',
	// baseURL: 'http://49.235.135.23:2020/cms',
	// baseURL: 'http://localhost:2020/cms/',
	timeout: 60000,
});
// made 6...
// 添加请求拦截器
axiosIstance.interceptors.request.use(config => {
	// 在发送请求之前做些什么
	// 解决清楚本地token后vuex未清除导致携带
	const token = localStorage.getItem('token')
	// if (store.state.token) {
	if (token) {
		config.headers['Authorization'] = "Bearer " + store.state.token
	}
	//loading加载动画
	store.state.isShow = true
	return config;
}, error => {
	return Promise.reject(error);
});
// 添加响应拦截器
axiosIstance.interceptors.response.use(response => {
	let token = response.headers['token']
	if (token) {
		store.commit('setToken', token)
	}
	//获取后端返回的对象
	const res = response.data
	if (response.status == 200) {
		// setTimeout(() => {
			store.state.isShow = false //loading加载动画
		// }, 1000)
	}
	//数据正常响应
	if (res.status == 200) {
		return res;
	} else if (res.status === 500) {
		Message({
			message: res.message || 'Error',
			type: 'error',
			duration: 5 * 1000
		})
	} else {
		return Promise.reject(res.message)
	}

}, error => {
	if (error.response) {
		if (error.response.data.status == 401) {
			token.clear()
			store.state.isShow = false //loading加载动画
			router.push("/userLogin")
			Message({
				message: error.response.data.message || 'Error',
				type: 'error',
				duration: 3000
			})
		}
		if (error.response.data.status == 403) {
			token.clear()
			store.state.isShow = false //loading加载动画
			router.push("/userLogin")
			Message({
				message: error.response.data.message || 'Error',
				type: 'error',
				duration: 3000
			})
		}
	} else if (error.message.includes('timeout')) {
		// 如果是超时错误，仅显示一个简单的提示  
		Message({
			message: '请求服务器超时！' || 'Error',
			type: 'error',
			duration: 2000
		})
	} else {
		// 对于其他类型的错误，可以选择性地记录或处理  
		console.error('Error occurred:', error);
	}

	// 总是返回一个被拒绝的Promise，以保持链的完整性  
	return Promise.reject(error);
});

export default axiosIstance
