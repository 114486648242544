<template>
    <div v-title data-title="博客后台 | 游戏信息">
        <div>
            <el-form :inline="true" ref="queryForm" :model="queryForm" label-width="80px">
                <el-form-item>
                    <el-button icon="el-icon-plus" @click="add" type="success">新增</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="id" label="ID" width="180">
            </el-table-column>
            <el-table-column prop="gameName" label="游戏名称">
            </el-table-column>
            <el-table-column prop="description" label="游戏描述">
            </el-table-column>
            <el-table-column prop="gameHrefLocation" label="跳转地址">
            </el-table-column>
            <el-table-column align="center" width="150" label="操作">
                <template slot-scope="scope">
                    <el-button @click="del(scope.row)" size="small" type="danger" icon="el-icon-delete"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="addVisble" v-if="addVisble" :close-on-click-modal="false">
            <Add @after="addseach" @hideDialog="hidden"></Add>
        </el-dialog>

    </div>
</template>

<script>
import { getGame,del} from "@/api/game";
import Add from './Add'


export default {
    name: "Index",
    components: {
        Add
    },
    data() {
        return {
            queryForm: {
                name: '',
                userName: ''
            },
            tableData: [],
            formData: {},
            addVisble: false,
            editVisble: false
        }
    },
    mounted() {
        this.list({ "page": this.pageNo })
    },
    methods: {
        list() {
            getGame().then(data => {

                this.tableData = data.data
                this.total = data.total
            }).catch(error => {
                this.$message.error(error)
            })
        },
        del(row) {
            this.$confirm('确定要删除名称为<' + row.gameName + '>的游戏吗？', '提示').then(() => {
                del(row.id).then(data => {
                    let param = this.queryForm
                    param.page = this.pageNo
                    this.list(param)
                    this.$message.success("删除成功")
                }).catch(error => {

                    this.$message.error(error)
                })
            }).catch(error => {

            })
        },
        add() {
            this.$router.push('/gameAdd')
        },
       
        addseach() {
            let param = this.queryForm
            param.page = this.pageNo
            this.list(param)
            query(param).then(data => {
                this.pages = data.pages
                this.handleCurrentChange(this.pages)
            }).catch(error => {
                this.$message.error(error)
            })
        },
    }
}
</script>

<style scoped></style>