import axiosInstall from './axiosInstance';



export function create(game) {
    return axiosInstall.post('game/add',game)
}


export function getGame() {
    return axiosInstall.get('game/list')
}

export function del(id) {
    return axiosInstall.get('game/delete?id='+id)
}

