<template>
	<div class="login-page" v-title data-title="忘记密码" style="height: 100vh;">
		<transition appear name="animate__animated animate__bounce" enter-active-class="animate__bounceInUp">
		<div class="login-page-bg">
			<h2 style="opacity: 1;font-weight: 300">重置密码</h2>
			<el-form ref="form" :model="form" :rules="rules" label-width="80px">
				<el-form-item prop="email">
					<el-input placeholder="请输入邮箱" prefix-icon="el-icon-user-solid" v-model="form.email"></el-input>
				</el-form-item>
				<el-form-item prop="verifyCode">
					<div class="bind_code margin_top">
						<el-input class="bind_code_input code" placeholder="请输入验证码" prefix-icon="el-icon-key" v-model="form.verifyCode"></el-input>
						<el-button class="bind_code_gain" :disabled="two" @click="sendCode()">{{ btnText }}
						</el-button>
					</div>
				</el-form-item>
				<el-form-item prop="password">
					<el-input show-password placeholder="请输入新密码" prefix-icon="el-icon-lock" v-model="form.password"></el-input>
				</el-form-item>
				<el-form-item prop="checkPass">
					<el-input show-password placeholder="请再次输入密码" prefix-icon="el-icon-lock" v-model="form.checkPass"></el-input>
				</el-form-item>
				<el-form-item>

				</el-form-item>
				<el-form-item>
					<el-button style="width: 35%; color: greenyellow; float: right;" type="primary" :loading="loading"
						native-type="submit" @click="submit()">修改</el-button>
					<el-button style="width: 35%; color: red; float: left; " type="primary" :loading="loading" native-type="submit"
						@click="goLogin()">返回登录</el-button>
				</el-form-item>

			</el-form>
		</div>
	</transition>
	</div>
</template>

<script>
import { sendCode, updateBE } from '../../api/user'
export default {
	name: "forgetPwd",
	data() {
		var validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				if (this.form.checkPass !== '') {
					this.$refs.form.validateField('checkPass');
				}
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.form.password) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			form: {
				email: '',
				verifyCode: '',
				checkPass: '',
				password: ''
			},
			btnText:'发送验证码',
			two: false,
			loading: false,
			rules: {
				password: [{
					validator: validatePass,
					trigger: 'blur',
				}, {
					min: 6, max: 10, message: '长度在 6 到 10 个字符', trigger: 'blur'
				}],
				checkPass: [{
					validator: validatePass2,
					trigger: 'blur'
				}],
				email: [
					{ required: true, message: '请输入邮箱地址', trigger: 'blur' },
					{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
				],
				verifyCode: [
					{ required: true, message: '请输入验证码', trigger: 'blur' },
				]
			},
		}
	},

	created() {
		//页面加载完全局监听键盘事件，然后调用函数
		document.addEventListener("keyup", this.watchEnter)
	},
	destroyed() {
		//页面跳转销毁全局监听事件
		document.removeEventListener("keyup", this.watchEnter)
	},
	methods: {
		goLogin() {
			this.$router.push('/userlogin')
		},
		sendCode() {
			if (this.form.email === "") {
				this.$message.error('请先输入邮箱!!!')
			} else {
				this.loading = true
				sendCode(this.form.email).then(data => {
					this.$message.success('发送成功!!')
					this.loading = false
					this.two = true;
          this.btnText = "请稍候...";
          setTimeout(() => {
            this.doLoop(60);
          }, 500);
				}).catch(error => {
					this.loading = false
					this.$message.error(error)
				})
			}
		},
		doLoop: function(seconds){
			seconds = seconds ? seconds : 60;
      this.btnText = seconds + "s后获取";
      // this.code = code
      let countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + "s后获取";
          --seconds;
        } else {
          this.btnText = "获取验证码";
          this.two = false;
          clearInterval(countdown);
        }
      }, 1000);

		},
		submit() {
			if (this.form.password !== this.form.checkPass) {
				this.$message.error('两次密码不一致')
			} else {
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.loading = true
						updateBE(this.form).then(data => {
							this.loading = false
							this.$message.success(data.message)
							this.$router.push('/userlogin')
						}).catch(error => {
							this.loading = false
							this.$message.error(error)
						})
					}
				})
			}

		}
	}
}
</script>

<style scoped lang="scss">
.login-page {
	height: 100%;
	background-size: cover;
}

.bind_code {
	position: relative;
	.el-input__inner {
		width: 328px;
		height: 44px;
		background: #f7f7f7;
		border-radius: 4px;
		border: 1px solid #e4e4e4;
		outline: none;
		padding: 0 100px 0 10px;
	}

	.code /deep/.el-input__suffix {
		right: 97px;
	}

	.el-button {
		border: 0;
		background: none;
		padding: 0;
		border-radius: 0;
	}

	.pic {
		width: 80px;
		height: 25px;
		border-left: none !important;
	}

	.bind_code_gain {
		position: absolute;
		top: calc(50% - 9px);
		right: 1%;
		font-size: 14px;
		font-family: MicrosoftYaHei;
		color: #20aee5;
		line-height: 18px;
		cursor: pointer;
		padding-left: 10px;
		border-left: 1px solid #d8d8d8;
	}

	.login-icon {
		width: 16px;
		height: 16px;
		position: absolute;
		top: 6px;
		left: 88px;
	}
}

.login-page-bg {
    position: absolute;
    top: 30%;
    left: 50%;
    right: 30%;
    bottom: 25%;
    width: 500px;
    height: 400px;
    border-radius: 5px;
    padding-top: 20px;
    color: white;
    text-align: center;
    padding-right: 50px;
    box-shadow: #666 0px 0px 10px;
    -webkit-box-shadow: #666 0px 0px 10px;
    -moz-box-shadow: #666 0px 0px 10px;
    margin-left: -250px;

}

/deep/ .el-form-item__content {
	line-height: 30px;
	position: relative;
	font-size: 14px;
}
/deep/ .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all .3s;
    line-height: 40px;
    position: relative;
    bottom: 4px;
}
/deep/ .el-input .el-input__clear {
    color: #C0C4CC;
    font-size: 14px;
    cursor: pointer;
    transition: color .2s cubic-bezier(.645,.045,.355,1);
    position: relative;
    left: -82px;
}
.login-page-bg h2 {
	margin-bottom: 30px;
	margin-left: 7%;
}

.el-button {
	display: inline-block;
	line-height: 1;
	white-space: nowrap;
	cursor: pointer;
	background: #53acff;
	border: 1px solid #DCDFE6;
	color: #606266;
	text-align: center;
	box-sizing: border-box;
	outline: 0;
	margin: 0;
	transition: .1s;
	font-weight: 500;
	padding: 8px 10px;
	font-size: 14px;
	border-radius: 10px;

}

/deep/ .el-input__inner {

	background-color: #ffffff;
	border-radius: 30px;
	border: 1px solid #DCDFE6;
	box-sizing: border-box;
	color: #606266;
	display: inline-block;
	/* font-size: inherit; */
	height: 30px;
	line-height: 20px;
	outline: 0;
	padding: 0 30px;
	transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
	width: 100%;
}

/deep/ .el-form-item__error {
	color: #F56C6C;
	font-size: 12px;
	line-height: 2;
	padding-top: 4px;
	position: absolute;
	top: 85%;
	left: 5px;
}</style>
