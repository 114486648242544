import Vue from 'vue'

import VueRouter from 'vue-router'
import Login from '../views/admin/Login'
import Index from "../views/admin/Index";
import User from "../views/admin/user/Index";

import Tag from "../views/admin/tag/Index";
import Channel from "../views/admin/channel/Index";
import FriendLink from "../views/admin/friendlink/Index";
import FriendLinkAdd from "../views/admin/friendlink/Add";
import FriendLinkEdit from "../views/admin/friendlink/Edit";
import Article from "../views/admin/article/Index";
import Comment from "../views/admin/comment/Index";
import Check from "../views/admin/comment/Checkl";
import Info from "../views/admin/user/Info";
import Channel_add from '../views/admin/channel/Add'
import Channel_edit from '../views/admin/channel/Edit'
import ArticleAdd from '../views/admin/article/Add'
import ArticleEdit from '../views/admin/article/Edit'
import UserLogin from '../views/admin/loginRegister.vue'
import PersonHome from '../views/admin/person/PersonHome'
import PersonInfo from '../views/admin/person/Info'
import PersonArticle from '../views/admin/person/MyArticle'
import MyArticlePub from '../views/admin/person/myArticle/Index'
import MyArticleEdit from '../views/admin/person/myArticle/Edit'
import PersonCollect from '../views/admin/person/MyCollect'
import PersonFanAndFollow from '../views/admin/person/MyFanAndFollow'
import FIndex from '../views/front/Index'
import Game from '../components/front/Game'
import List from '../views/front/List'
import Detail from '../views/front/Detail'
import ChannelDetail from '../views/front/ChannelDetail'
import TagList from '../views/front/TagList'
import forgetPwd from '../views/admin/forgetPwd'
import swiper from '../views/admin/swiper/Index'
import SwiperAdd from '../views/admin/swiper/Add'
import gameAdd from '../views/admin/game/Add'
import gameIndex from '../views/admin/game/Index'

import store from '../store/index'


Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {

	return originalPush.call(this, location).catch(err => err)

}

const router = new VueRouter({
	mode: "history",
	routes: [{
		path: '/',
		name: 'fIndex',
		component: FIndex,
		meta: {
			requireLogin: false,
		}
	},
	{
		path: '/content',
		name: 'content',
		component: FIndex,
		meta: {
			requireLogin: false,
			index: 0,
		}
	},

	{
		path: '/list/:id',
		name: 'list_id',
		component: List,
		meta: {
			requireLogin: false,
			index: 1,
		}
	},
	{
		path: '/detail/:id',
		name: 'detail',
		component: Detail,
		meta: {
			requireLogin: false,
			index: 0,
		}
	},
	{
		path: '/channel_detail/:id',
		name: 'channel_detail',
		component: ChannelDetail,
		meta: {
			requireLogin: false,
		}
	},

	{
		path: '/tag_list/:id',
		name: 'tag_list/:id',
		component: TagList,
		meta: {
			requireLogin: false,
			index: 1
		}
	},
	{
		path: '/login',
		name: 'login',
		component: Login,

	},
	{
		path: '/game',
		name: 'game',
		component: Game,
	},
	{
		path: '/userlogin',
		name: 'userlogin',
		component: UserLogin
	},
	{
		path: '/forget',
		name: 'forgetPwd',
		component: forgetPwd
	},
	{
		path: '/PersonHome',
		name: 'PersonHome',
		component: PersonHome,
		children: [
			{
				// path: '/personal/info/:id',
				path: '/personinfo',
				name: 'personinfo',
				component: PersonInfo
			},
			{
				path: '/myarticle',
				name: 'myarticle',
				component: PersonArticle
			},
			{
				path: '/mycollect',
				name: 'mycollect',
				component: PersonCollect
			},
			{
				path: '/articlePub',
				name: 'articlePub',
				component: MyArticlePub
			},
			{
				path: '/myArticleEdit',
				name: 'myArticleEdit',
				component: MyArticleEdit
			},
			{
				path: '/myfan',
				name: 'myfan',
				component: PersonFanAndFollow
			},
			{
				path: '/myfollow',
				name: 'myfollow',
				component: PersonFanAndFollow
			}
		]
	},
	{
		path: '/index',
		name: 'index',
		component: Index,
		meta: { requireAuth: true },

		children: [{
			path: '/info',
			name: 'info',
			component: Info,
			meta: { requireAuth: true }
		},
		{
			path: '/user',
			name: 'User',
			component: User,
			meta: { requireAuth: true }
		},

		{
			path: '/channel',
			name: 'channel',
			component: Channel,
			meta: { requireAuth: true }
		},
		{
			path: '/channel_add',
			name: 'channel_add',
			component: Channel_add,
			meta: { requireAuth: true }
		},
		{
			path: '/channel_edit',
			name: 'channel_edit',
			component: Channel_edit,
			meta: { requireAuth: true }
		},
		{
			path: '/tag',
			name: 'tag',
			component: Tag,
			meta: { requireAuth: true }
		},
		{
			path: '/friend',
			name: 'friend',
			component: FriendLink,
			meta: {
				index: 2,
			},
			meta: { requireAuth: true }
		},
		{
			path: '/friend_add',
			name: 'friend_add',
			component: FriendLinkAdd,
			meta: { requireAuth: true }
		},
		{
			path: '/friend_edit',
			name: 'friend_edit',
			component: FriendLinkEdit,
			meta: { requireAuth: true }
		},
		{
			path: '/article',
			name: 'article',
			component: Article,
			meta: { requireAuth: true }
		},
		{
			path: '/article_add',
			name: 'article_add',
			component: ArticleAdd,
			meta: { requireAuth: true }
		},
		{
			path: '/article_edit',
			name: 'article_edit',
			component: ArticleEdit,
			meta: { requireAuth: true }
		},
		{
			path: '/comment',
			name: 'comment',
			component: Comment,
			meta: { requireAuth: true }
		},
		{
			path: '/comment_check',
			name: 'comment_check',
			component: Check,
			meta: { requireAuth: true }
		},
		{
			path: '/swiper',
			name: 'swiper',
			component: swiper,
			meta: { requireAuth: true }
		},
		{
			path: '/swiperadd',
			name: 'SwiperAdd',
			component: SwiperAdd,
			meta: { requireAuth: true }
		},
		{
			path: '/gameIndex',
			name: 'gameIndex',
			component: gameIndex,
			meta: { requireAuth: true }
		},
		{
			path: '/gameAdd',
			name: 'gameAdd',
			component: gameAdd,
			meta: { requireAuth: true }
		}
		]
	}
	],
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	}
})

router.beforeEach((to, from, next) => {

	if (!to.matched.some(r => r.meta.requireLogin)) {
		next()
	} else {
		next('/login')
	}

	if (to.meta.requireAuth) {  // 需要权限
		//判断当前是否拥有权限
		if (store.state.user.admin === '1' || store.state.user.admin === '2') {
			next();
		} else {  // 无权，跳转登录
			next({
				path: '/',
			})
		}

	} else {  // 不需要权限，直接访问
		next();
	}
})

export default router
