<template>
  <div class="myart1">
    
   <ArticleAdd/>

    <!-- <el-empty v-if="allData.length == 0" :image-size="250" description="暂未发表任何文章额"></el-empty> -->
 
  </div>
</template>
<script>
import ArticleAdd from '@/views/admin/article/Add'
export default {
  components: { ArticleAdd },
  name: "MyArticle",
  data() {
    return {
     
    };
  },
};
</script>

<style>
.myart1 {
  line-height: 30px;
  position: relative;
    right: 3%;
    margin-top: 2%; 
}
</style>
